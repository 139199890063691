import { useState, useEffect } from 'react';
import debounce from './debounce';

export const breakpointsDefinitions = {
    xSmall: { min: 0, max: 550 },
    small: { min: 481, max: 767 },
    medium: { min: 768, max: 1023 },
    large: { min: 1024, max: 1439 },
    xlarge: { min: 1440, max: 1919 },
    xxlarge: { min: 1920, max: 159983 },
} as const;

type BreakpointName = keyof typeof breakpointsDefinitions

const isBreakPointInRange = (minBreakpointName: BreakpointName, maxBreakpointName: BreakpointName) => {
    const minLimit = breakpointsDefinitions[minBreakpointName].min;
    const maxLimit = breakpointsDefinitions[maxBreakpointName].max;

    return window.matchMedia(`(min-width: ${minLimit}px) and (max-width: ${maxLimit}px)`).matches;
};

function useBreakpoint(minBreakpointName: BreakpointName, maxBreakpointName: BreakpointName) {
    const [isBreakpoint, setIsBreakpoint] = useState(false);

    useEffect(() => {
        setIsBreakpoint(isBreakPointInRange(minBreakpointName, maxBreakpointName));

        const handleResize = debounce(() => {
            setIsBreakpoint(isBreakPointInRange(minBreakpointName, maxBreakpointName));
        }, 500);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [minBreakpointName, maxBreakpointName]);

    return isBreakpoint;
}

export default useBreakpoint;
